<template>
  <b-container>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="newModel"
        >
          <FeatherIcon icon="PlusIcon" />
          &nbsp;<span class="text-nowrap">Adicionar</span>
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      :show-pagination="false"
      @onChangePage="getData"
    >
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />
        </div>
      </template>
    </Grid>
    <station-file-form
      :is-active.sync="isActivated"
      :uuid="currentModel"
      :station-uuid="stationUuid"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
  </b-container>

</template>

<script>

import StationFileForm from '@/views/stationfile/StationFileForm.vue'

export default {
  name: 'StationFileList',
  components: { StationFileForm },
  props: {
    stationUuid: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-40',
        },

        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentModel: '',
      items: [],
      isActivated: false,
    }
  },
  watch: {
    stationUuid() {
      this.getData()
    },
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData() {
      if (this.$props.stationUuid) {
        this.items = await this.$http.get(`stations/${this.$props.stationUuid}/files`)
      }
    },

    setCurrentModel(model) {
      this.isActivated = true
      this.currentModel = model.uuid
    },

    onDelete(model) {
      const route = `station-files/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do arquivo?',
        'Excluir',
        () => {
          this.$notify.success('Arquivo removido com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData()
      this.isActivated = false
      this.currentModel = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentModel = ''
    },
  },
}
</script>

<style scoped>

</style>
